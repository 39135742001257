import React from 'react'
import Link from 'next/link'
import { css, SerializedStyles } from '@emotion/core'
import Label from '../../common/Label'
import { getCategoryRoute } from './blogUtils'
import { GRAY_DARKER, BLUE, PURPLE, YELLOW } from '../../../constants/theme.styles'

const linkBaseStyles = css`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: 600;
`

const iconStyles = css`
  margin-right: 10px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
`

type CategoryLinkSize = 'small' | 'medium'
export type SupportedCategoryColors = 'dark' | 'blue' | 'purple' | 'yellow' | null

interface IProps {
  name: string
  size?: CategoryLinkSize
  href?: string | null
  label?: SupportedCategoryColors
  dashed?: Boolean
}

const linkSizeStyles = {
  small: css`
    font-size: 10px;
  `,
  medium: css`
    font-size: 14px;
  `
}

interface IColorStyles {
  dark: SerializedStyles
  blue: SerializedStyles
  purple: SerializedStyles
  orange: SerializedStyles
  yellow: SerializedStyles
}

const colorStyles: IColorStyles = {
  dark: css`
    background: ${GRAY_DARKER};
    color: #fff;
  `,
  blue: css`
    background: ${BLUE};
    color: #fff;
  `,
  purple: css`
    background: ${PURPLE};
    color: #fff;
  `,
  orange: css`
    background: #ef8945;
  `,
  yellow: css`
    background: ${YELLOW};
  `
}

const CategoryLink: React.FC<IProps> = ({
  name,
  href,
  label,
  size = 'medium',
  dashed = false,
  ...rest
}: IProps) => {
  const knownCategoryRoute = href && getCategoryRoute(name) ? getCategoryRoute(name) : null
  if (!knownCategoryRoute) {
    return (
      <span css={[linkBaseStyles, linkSizeStyles[size]]} {...rest}>
        {label ? (
          <span
            css={[
              iconStyles,
              css`
                background-color: ${colorStyles[label]};
              `
            ]}
          />
        ) : null}
        {(dashed ? '\u{2014} ' : '') + name}
      </span>
    )
  }

  return (
    <Link
      href={{
        pathname: '/category',
        query: {
          categorySlug: name
            .split(' ')
            .join('-')
            .toLowerCase()
        }
      }}
      as={knownCategoryRoute}
      passHref
    >
      <a css={[linkBaseStyles, linkSizeStyles[size]]} {...rest}>
        {label ? <Label circular size="micro" color={label} css={iconStyles} /> : null}
        <span className="category-link-text">{name}</span>
      </a>
    </Link>
  )
}

export default CategoryLink
